<template>
  <div class="wrap-edit">
    <el-card>
      <FloatTitBtn>
      <div class="edit-header">
        <span>建设工程规划竣工确认书</span>
        <div>
          <el-button type="primary" @click="print">打印</el-button>
          <el-button type="primary" v-show="!isDisabled" @click="onSubmit"
          >保存</el-button
          >
        </div>
      </div>
      </FloatTitBtn>

      <div class="edit-container">
        <el-form
          ref="baseform"
          :label-position="labelPosition"
          :model="form"
          label-width="120px"
        >
          <el-row :gutter="24">
            <el-col :span="24">
              <el-form-item label="文号:">
                <el-input v-model="form.WH"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="24">
            <el-col :span="24">
              <el-form-item label="建设单位:">
                <el-input v-model="form.JSDW" disabled></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="24">
            <el-col :span="24">
              <el-form-item label="项目名称:">
                <el-input v-model="form.XMMC" disabled></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="24">
            <el-col :span="24">
              <el-form-item label="建设位置:">
                <el-input v-model="form.JSWZ" disabled></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="24">
            <el-col :span="24">
              <el-form-item label="建设规模:">
                <el-input v-model="form.JSGM" :disabled="isDisabled">
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="24">
            <el-col :span="24">
              <el-form-item label="建设工程规划许可证号:">
                <el-input v-model="form.JSGCGHXKZH" :disabled="isDisabled"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="24">
            <el-col :span="24">
              <el-form-item label="附件附图名称:">
                <el-input
                  v-model="form.FTMC"
                  type="textarea"
                  :rows="3"
                  :disabled="isDisabled"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="24">
            <el-col :span="12"> </el-col>
            <el-col :span="12">
              <el-form-item label="发证日期:">
                <el-date-picker
                  style="width: 100%"
                  v-model="form.FZRQ"
                  :clearable="false"
                  format="YYYY-MM-DD"
                  value-format="YYYY-MM-DD"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </el-card>
    <div v-show="false">
      <div id="form2">
       <div style="display: flex;width: 100%;height: 98vh;font-family: 'SimSun';font-size: 21px">
         <div style="flex: 1;border: 1px solid black;margin-right: 80px">
           <div style="font-size: 47px;margin-top: 100px;text-align: center;font-weight: bold">浙江省建设工程规划核实确认书</div>
           <div style="display: flex;padding-left: 35%;margin-top: 50px">
             <div>浙规核字第</div>
             <div style="min-width: 230px;border-bottom: 1px solid #000000">{{ form.WH }}</div>
             <div>号</div>
           </div>
           <div style=";text-indent: 2em;padding: 50px 40px;margin-top: 70px;font-size: 30px;font-weight: bold;line-height: 42px">
             依据《中华人民共和国城乡规划法》第四十五条和《浙江省城乡规划条例》第四十四条及国家、省有关规定,经核实,本建设工程已具备竣工规划确认条件,颁发此书。
           </div>
           <div style="margin-top: 230px;padding-left: 300px">
             <div style="margin-bottom: 20px">核发机关:慈溪市自然资源和规划局</div>
             <div>日期:{{ getDate(form.FZRQ) }}</div>
           </div>
         </div>
         <div style="flex: 1;border: 1px solid black">
           <div style="width: 100%;display: flex;justify-content: center;margin-top: 30px;">
             <table  border="1" style="border-collapse: collapse;width: 90%;">
               <tr style="height: 63px">
                 <th style="width: 37%">建设单位(个人)</th>
                 <td style="width: 63%;text-align: center">{{ form.JSDW }}</td>
               </tr>
               <tr style="height: 63px">
                 <th style="width: 37%">建设项目名称</th>
                 <td style="width: 63%;text-align: center">{{ form.XMMC }}</td>
               </tr>
               <tr style="height: 63px">
                 <th style="width: 37%">建设位置</th>
                 <td style="width: 63%;text-align: center">{{ form.JSWZ }}</td>
               </tr>
               <tr style="height: 63px">
                 <th style="width: 37%">建设规模</th>
                 <td style="width: 63%;text-align: center">{{ form.JSGM }}</td>
               </tr>
               <tr style="height: 63px">
                 <th style="width: 37%">建设工程规划许可证号</th>
                 <td style="width: 63%;text-align: center">{{ form.JSGCGHXKZH }}</td>
               </tr>
               <tr style="height: 290px">
                 <td colspan="2" >
                   <div style="display: flex">
                     <div style="flex: 1.5;margin-left: 30px;padding-bottom: 230px">附图及附件名称:</div>
                     <div  style="flex: 4;margin-left: 10px">
                       <div  v-for="(v, i) in cllist" :key="i" style="font-size: 17px;width:100%;margin-bottom: 3px"> {{ v }} </div>
                     </div>
                   </div>
                 </td>
               </tr>
             </table>
           </div>
           <div style="width: 100%;display: flex;justify-content: center;margin-top: 30px">
             <div style=";width: 90%;">
               <div style=";font-weight: bold;font-size: 33px">遵循事项</div>
               <div style="width: 100%;padding: 20px 0 0 20px;box-sizing: border-box">
                 <div style="margin-bottom: 7px;line-height: 33px">一、本确认书是建设工程经城乡规划主管部门依法核实具备竣工规划确认条件的法律凭证。</div>
                 <div style="margin-bottom: 7px;line-height: 33px">二、未取得本确认书的建设工程，建设单位不得组织竣工验收，建设主管部门不予竣工备案，房屋登记机构不予办理房屋所有权初始登记。</div>
                 <div style="line-height: 33px">三、本确认书的附图与附件由核发机关依法确定，与本确认书具有同等法律效力。</div>
               </div>
             </div>
           </div>
         </div>
       </div>
      </div>
    </div>
  </div>
</template>


<script>
import { ElMessage } from "element-plus";
import FloatTitBtn from '../../components/FloatTitBtn.vue';
import print from "print-js";
import dayjs from 'dayjs';
export default {
  name:'jsgzghjgqr',
  components: { FloatTitBtn },
  data() {
    return {
      labelPosition: "left",
      form: {
        Id: '',
        BJID: '',
        WH: '',
        JSDW: '',
        XMMC: '',
        JSWZ: '',
        JSGM: '',
        JSGCGHXKZH: '',
        FTMC: '',
        FZRQ:new Date(),
      },
      sblxdisabled: false,
      isDisabled: true,
      cllist: ['', '', '']
    };
  },
  methods: {
    getDate(date) {
      var a = date
      a= dayjs(date).format('YYYY年MM月DD日')
      return a
    },
    print() {
      print({
        printable: "form2",
        type: "html",
        scanStyles: false,
        css: "/static/landscapeA3.css",
        documentTitle: "",
      });
    },
    async onSubmit() {
      let me = this;
      if (me.checkform()) {
        await this.$ajax
          .post(me.$appConfig.apiUrls.ywxtApi + "/api/YW/AddorUpdatejsgcghjgqr", me.form)
          .then(function (response) {
            if (response.data.IsSuccess) {
              me.form.Id = response.data.Data;
              if(me.form.FTMC==null||me.form.FTMC==undefined){
                me.form.FTMC=''
              }
              me.cllist = me.form.FTMC.split('\n')
              ElMessage({
                type: "success",
                message: "保存成功",
              });
            } else {
              ElMessage({
                type: "info",
                message: "保存失败!",
              });
              console.log(response.data.errorMessage);
            }
          });
      }
    },
    //转化json
    switch() {
      let me = this;
      me.form.BJID = this.$route.query.instanceId;
      if (me.form.BJID) {
        this.$ajax
          .get(me.$appConfig.apiUrls.ywxtApi + "/api/YW/getjsgcghjgqr?BJID=" + me.form.BJID)
          .then(function (response) {
            if (response.data.IsSuccess) {
              me.form = response.data.Data;
              //me.form.FZRQ=new Date()
              me.getlahz();
               if (response.data.Data.FTMC != null && response.data.Data.FTMC.trim() != '') {
                me.cllist = me.form.FTMC.split('\n')
              }
            } else {
              ElMessage({
                type: "info",
                message: "初始化表单失败",
              });
            }
            if (me.form.JBR === me.$router.user.profile.sub && me.$route.query.taskId) {
              me.isDisabled = false;
            }
          });
      }
    },
    //获取数据
    async getlahz() {
      let me = this;
      try {
        await this.$ajax
          .get(
            this.$appConfig.apiUrls.ywxtApi +
            "/api/YW/Getlahz?Id=" +
            me.$route.query.instanceId
          )
          .then((response) => {
            if (response.data.IsSuccess) {
              let res = response.data.Data;
              me.form.JSDW = res.JSDW
              me.form.XMMC = res.XMMC
              me.form.JSWZ = res.JSWZ
            } else {
              console.log(response.data.ErrorMessage);
            }
          });
      } catch (error) {
        ElMessage({
          type: 'error',
          message: error,
        })
      }
    },
    checkform() {
      let b = true;
      let f = this.form;
      if (b) b = this.checkinput(f.JSDW, "建设单位");
      if (b) b = this.checkinput(f.XMMC, "项目名称");
      if (b) b = this.checkinput(f.JSWZ, "建设位置");
      if (b) b = this.checkinput(f.JSGM, "建设规模");
      if (b) b = this.checkinput(f.JSGCGHXKZH, "建设工程规划许可证号");
      if (b) b = this.checkinput(f.FTMC, "附件附图名称");

      return b;
    },
    checkinput(val, mess) {
      if (!val || val.length < 1) {
        ElMessage({
          type: "info",
          message: "请输入" + mess,
        });
        return false;
      } else {
        return true;
      }
    },

  },
  mounted() {
    this.switch();
  },
};
</script>

<style scoped>
.edit-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.el-form {
  width: 100%;
  overflow: hidden;
}
.input-width {
  width: 100%;
}
.wrap-edit .edit-header {
  margin-bottom: 15px;
}
</style>
